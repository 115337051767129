import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import cx from 'clsx';
import { useGaEvents } from 'hooks/useSendButtonClickedEvent';
import { MouseEventHandler, forwardRef, useCallback } from 'react';
import classes from './ActionButton.module.css';

export interface ActionButtonProps extends ButtonProps {
  gaName?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ActionButton = createPolymorphicComponent<
  'button',
  ActionButtonProps
>(
  forwardRef<HTMLButtonElement, ActionButtonProps>(
    (
      { className, classNames, styles, variant, gaName, onClick, ...other },
      ref,
    ) => {
      const { sendButtonClickedEvent } = useGaEvents();

      const internalOnClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (onClick) {
            onClick(event);
          }
          sendButtonClickedEvent(gaName);
        },
        [onClick, gaName, sendButtonClickedEvent],
      );

      return (
        <Button
          onClick={internalOnClick}
          ref={ref}
          className={cx(
            variant === 'outline' ? classes.buttonOutline : classes.button,
            className,
          )}
          classNames={classNames}
          styles={styles}
          variant={variant}
          {...other}
        />
      );
    },
  ),
);
