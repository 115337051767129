import {
  ActionIcon,
  Button,
  Combobox,
  ComboboxProps,
  Group,
  InputBase,
  Loader,
  Text,
  useCombobox,
} from '@mantine/core';
import { AnyChain } from '@moonbeam-network/xcm-types';
import { InputPasteButton } from 'components/InputPasteButton';
import { Jazzicon } from 'components/Jazzicon';
import { useIsMobile, useIsTablet } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';
import { IoWallet } from 'react-icons/io5';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import {
  useSubstrateAccounts,
  useSubstrateAddress,
} from 'recoil/substrateExtension';
import { type MrlChain } from 'services/mrl/chains/chain.types';
import { isAddressValid, toSS58Format } from 'utils/crypto';
import { sliceEllipsis } from 'utils/text';
import classes from './SelectSubstrateAccount.module.css';

export interface Props extends Omit<ComboboxProps, 'data'> {
  chain?: AnyChain | MrlChain;
  withPasteButton?: boolean;
  withConnectButton?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  labelProps?: Record<string, unknown>;
  onChange?: () => void;
}

export const SelectSubstrateAccount = ({
  chain,
  withPasteButton = false,
  withConnectButton = false,
  isDisabled = false,
  isLoading = false,
  onChange,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const isEvmChain = chain?.isEvmParachain();
  const keypairType = isEvmChain ? 'ethereum' : undefined;
  const accounts = useSubstrateAccounts(chain?.genesisHash, keypairType);

  const { address, setAddress } = useSubstrateAddress({
    allowManualAddress: withPasteButton,
    genesisHash: withPasteButton ? undefined : chain?.genesisHash,
    keypairType,
  });

  const { openSubstrate } = useIsConnectWalletOpen();
  const [isError, setIsError] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const data = useMemo(() => {
    const data = accounts.map(({ address, meta }) => ({
      label: meta.name || '',
      value: address,
      description: toSS58Format(address, chain?.ss58Format),
    }));

    if (address && !accounts.some((acc) => acc.address === address)) {
      const formatted = toSS58Format(address, chain?.ss58Format);

      data.unshift({
        label: isMobile
          ? sliceEllipsis(formatted, 2, 2)
          : sliceEllipsis(formatted, 7, 7),
        value: address,
        description: formatted,
      });
    }

    return data;
  }, [accounts, address, chain?.ss58Format, isMobile]);

  function renderConnectButton() {
    if (!withConnectButton || accounts.length) {
      return null;
    }

    if (isMobile || isTablet) {
      return (
        <ActionIcon onClick={openSubstrate}>
          <IoWallet />
        </ActionIcon>
      );
    }

    return (
      <Button
        size={'compact-md'}
        mr={'sm'}
        classNames={{ root: classes.rightButtonsRoot }}
        leftSection={<IoWallet className={classes.rightButtonColor} />}
        onClick={openSubstrate}
      >
        <Text className={classes.rightButtonColor}>{t('connectWallet')}</Text>
      </Button>
    );
  }

  const selectedOption = data.find((item) => item.value === address);

  const options = useMemo(
    () =>
      data.map(({ label, description, value }) => (
        <Combobox.Option value={value} key={value} active={address === value}>
          <Group wrap={'nowrap'}>
            <Jazzicon address={value} size={36} />
            <div>
              <Text size={'sm'} fw={'bold'}>
                {label}
              </Text>
              <Text size={'xs'}>
                {isMobile
                  ? sliceEllipsis(description, 7, 7)
                  : sliceEllipsis(description, 15, 15)}
              </Text>
            </div>
          </Group>
        </Combobox.Option>
      )),
    [address, data, isMobile],
  );

  return (
    <Combobox
      disabled={!accounts.length}
      store={combobox}
      onOptionSubmit={(value) => {
        combobox.toggleDropdown();

        if (value && !isAddressValid(value, keypairType)) {
          setIsError(true);

          return;
        }

        setIsError(false);
        setAddress(value || undefined);

        onChange?.();
      }}
      {...other}
    >
      <Combobox.Target>
        <InputBase
          disabled={isDisabled}
          component={'button'}
          type={'button'}
          label={t('accounts')}
          error={isError ? t('invalidAddress') : undefined}
          pointer
          classNames={{
            input: classes.input,
            section: classes.rightSections,
            label: classes.label,
          }}
          size={'md'}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={
            accounts.length && !withPasteButton ? 'none' : undefined
          }
          multiline
          rightSection={
            <>
              {withPasteButton && !isDisabled && accounts.length ? (
                <InputPasteButton
                  onClick={(value) => {
                    if (!value || !isAddressValid(value, keypairType)) {
                      setIsError(true);

                      return;
                    }

                    setIsError(false);
                    setAddress(value);
                  }}
                />
              ) : null}
              {accounts.length ? <Combobox.Chevron /> : renderConnectButton()}
            </>
          }
        >
          {selectedOption && !isLoading ? (
            <Group wrap={'nowrap'} gap={'xs'}>
              <Jazzicon address={selectedOption.value} size={24} />
              <Text>{selectedOption.label}</Text>
            </Group>
          ) : isLoading ? (
            <Loader type={'dots'} size={24} />
          ) : accounts.length ? (
            t('chooseAccount')
          ) : (
            t('notConnected')
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
