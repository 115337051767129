import { ActionButton } from 'components/ActionButton';
import { useIsTablet } from 'hooks/useMedia';
import { Wallet } from 'lib/wallets';
import { useTranslation } from 'next-i18next';
import { forwardRef, useMemo } from 'react';
import { IoWallet } from 'react-icons/io5';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import { useIsWalletSetupOpen } from 'recoil/isWalletSetupOpen';
import { useConnect } from 'wagmi';

export const WalletSetupButton = forwardRef<HTMLButtonElement>((_, ref) => {
  const { t } = useTranslation();
  const { open } = useIsWalletSetupOpen();
  const { toggleEvm } = useIsConnectWalletOpen();
  const { connectors, isPending } = useConnect();
  const isTablet = useIsTablet();

  // Filter WC
  const areWalletsAvailable = useMemo(
    () =>
      connectors.some(
        (connector) => connector && connector.name !== Wallet.WalletConnect,
      ),
    [connectors],
  );

  const isConnectWallet = areWalletsAvailable || isTablet;

  return (
    <ActionButton
      ref={ref}
      loading={isPending}
      leftSection={<IoWallet />}
      data-testid={'connect-wallet-button'}
      onClick={isConnectWallet ? toggleEvm : open}
    >
      {isConnectWallet ? t('connectWallet') : t('walletSetup.setupWallet')}
    </ActionButton>
  );
});

WalletSetupButton.displayName = 'WalletSetup/WalletSetupButton';
