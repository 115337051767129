import { Group, Modal, Text } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { useGaEvents } from 'hooks/useSendButtonClickedEvent';
import { useTranslation } from 'next-i18next';
import { useOffsiteModal } from 'recoil/offsiteModal/hooks';

export const OffsiteModal = () => {
  const { t } = useTranslation();
  const { isOpen, url, close } = useOffsiteModal();
  const { sendUrlOpenedEvent } = useGaEvents();

  return (
    <Modal
      opened={isOpen}
      onClose={close}
      title={t('offsiteLinkModal.title')}
      size={'lg'}
    >
      <Text>{t('offsiteLinkModal.message')}</Text>
      <Group mt={'xl'} justify={'right'}>
        <ActionButton onClick={close} variant={'outline'}>
          {t('cancel')}
        </ActionButton>
        <ActionButton
          component={'a'}
          href={url}
          target={'_blank'}
          rel={'noopener noreferrer'}
          onClick={(event) => {
            url && sendUrlOpenedEvent(url);
            close();
            event.preventDefault();
            window.open(url, '_blank', 'noopener,noreferrer');
          }}
        >
          {t('offsiteLinkModal.confirm')}
        </ActionButton>
      </Group>
    </Modal>
  );
};
